<template>
  <container>
    <div class="d-flex justify-content-end mb-2">
      <div class="mr-2 d-inline-flex flex-column">
        <label><b>Show Expired</b></label>
        <DxSwitch v-model="viewExpired" :key="viewExpired" />
      </div>
    </div>
    <div class="p-2 bg-white">
      <dx-grid-with-search
        :dataSource="contactSearch"
        :columns="columns"
        :show-borders="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :toolbar="toolbar"
        @initialized="initGrid"
        :searchPanel="searchPanel"
        :columnChooser="true"
        :paging="paging"
        :pager="pager"
        :remoteOperations="remoteOperations"
        gridName="contact"
      >
        <template v-slot:title>
          <page-title>Provider/Location Maintenance</page-title>
        </template>
        <template v-slot:extraActions>
          <div class="d-flex">
            <icon-button
              :disabled="!permissions.ProviderCreateEdit"
              @click.stop="openModal = 'provider'"
              type="button"
              class="btn btn-primary mr-2"
              icon="user"
            >
              <span class="mx-1"> Add Provider </span>
            </icon-button>
            <icon-button
              :disabled="!permissions.LocationCreateEdit"
              @click.stop="openModal = 'location'"
              type="button"
              class="btn btn-primary mr-2"
              iconClass="text-danger"
              icon="hospital-alt"
            >
              <span class="mx-1"> Add Location </span>
            </icon-button>
            <icon-button
              v-if="permissions.ContactCreateEdit"
              type="button"
              id="editBtn"
              @click="editSelected"
              icon="pen-alt"
              class="btn edit-btn btn-primary d-flex align-items-center justify-content-between"
            >
              <span class="ml-2">Edit</span>
            </icon-button>
          </div>
        </template>
        <template v-slot:actions-cell="{ data: { data, key } }">
          <!-- Should update once we have the contact endpoint -->
          <div class="d-flex align-items-center justify-content-center">
            <router-link
              v-if="permissions.ProviderCreateEdit"
              v-tooltip.left="'Edit provider'"
              :to="{
                name: 'ProviderUpdate',
                params: {
                  providerId: data.provider.id
                }
              }"
              class="mr-2 border-0 p-0"
            >
              <icon class="m-auto" icon="user" />
            </router-link>
            <router-link
              v-if="permissions.LocationCreateEdit"
              v-tooltip.left="'Edit location.'"
              :to="{
                name: 'LocationUpdate',
                params: {
                  locationId: data.location.id
                }
              }"
              class="mr-2 border-0 p-0"
            >
              <icon class="m-auto text-danger" icon="hospital-alt" />
            </router-link>
            <icon-button icon="pen-alt" class="text-primary p-0" @click="editContact(key)" />
          </div>
        </template>
        <template class="d-flex" v-slot:providerName="{ data }">
          <span
            class="contactLink"
            v-if="permissions.ProviderCreateEdit"
            @click="editProvider(data.data.provider.id)"
            >{{ providerName(data) }}</span
          >
          <span v-else>{{ providerName(data) }}</span>
        </template>
        <template class="d-flex" v-slot:locationName="{ data }">
          <span
            class="contactLink"
            v-if="permissions.LocationCreateEdit"
            @click="editLocation(data.data.location.id)"
            >{{ locationName(data) }}</span
          >
          <span v-else>{{ locationName(data) }}</span>
        </template>

        <template v-slot:headerCell="{ data }">
          <div class="accession-header">
            <b class="mr-auto">{{ data.column.caption }}</b>
          </div>
        </template>
        <template v-slot:expirationCell="{ data }">
          <ExpiryTemplate :expiry-date="data.value" />
        </template>
      </dx-grid-with-search>
      <div class="row justify-content-end"></div>
      <div class="container"></div>
      <Modal @close="cancelEdit" :status="isEditing">
        <div>
          <ContactsForm
            ref="contactForm"
            @cancel="cancelEdit"
            @submit="handleSubmit"
            v-model="selectedRows"
          />
        </div>
      </Modal>
      <Modal :status="openModal === 'provider'" @close="closeModal">
        <Provider
          @close="closeModal"
          @cancel="closeModal"
          :providerId="modalProviderId"
          :isModal="true"
        />
      </Modal>
      <Modal :status="openModal === 'location'" @close="closeModal">
        <Location
          @close="closeModal"
          @cancel="closeModal"
          :locationId="modalLocationId"
          :isModal="true"
        />
      </Modal>
    </div>
  </container>
</template>

<script>
import { ProvidersApi } from "@/services";
import Modal from "@/components/common/Modal";
import ContactsForm from "@/components/forms/CodeMaintenance/Providers/ContactsForm";
import { mapState, mapGetters } from "vuex";
import { createLogItem, dateRangeFilter, formatPhoneNumber } from "../../modules/helpers";
import auditLog from "../../services/AuditLog";
import ExpiryTemplate from "@/components/common/ExpiryTemplate.vue";
import Container from "../common/Container.vue";
import IconButton from "../common/IconButton.vue";
import Icon from "../common/Icon.vue";
import DataSource from "devextreme/data/data_source";
import PageTitle from "../common/PageTitle.vue";
import DxGridWithSearch from "../common/DxGridWithSearch.vue";
import Provider from "../forms/CodeMaintenance/Providers/Provider.vue";
import Location from "../forms/CodeMaintenance/Providers/Location.vue";
import DxSwitch from "devextreme-vue/switch";

export default {
  name: "Contacts",
  metaInfo: {
    title: "Provider/Location Maintenance",
    titleTemplate: "IntelliPath - %s"
  },
  components: {
    ContactsForm,
    Modal,
    ExpiryTemplate,
    Container,
    IconButton,
    Icon,
    PageTitle,
    DxGridWithSearch,
    Provider,
    Location,
    DxSwitch
  },
  mounted() {
    auditLog.insertLogMessage({
      ...createLogItem({}, 7),
      comments: "Visited Admin Provider/Location page."
    });
    this.$store.dispatch("dropdowns/getStates");
  },
  data() {
    return {
      isEditing: false,
      isLoading: false,
      hasSelection: false,
      contactSearch: new DataSource({
        store: ProvidersApi.searchContacts,
        pageSize: 10,
        filter: this.viewExpired
          ? null
          : dateRangeFilter("properties.effectiveOn", "properties.expiryOn")
      }),
      locationSearch: new DataSource({ store: ProvidersApi.searchLocations }),
      selectedRows: [],
      remoteOperations: {
        paging: true,
        filtering: true,
        sorting: true
      },
      headerFilter: {
        visible: true
      },
      filterRow: {
        visible: true
      },
      columnChooser: {
        mode: "select",
        enabled: true
      },
      toolbar: {
        items: [
          {
            template: "title",
            location: "before"
          },
          {
            template: "extraActions",
            location: "after"
          },
          {
            widget: "dxButton",
            visible: true,
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                "data-testId": "toolbar-action-excel",
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      },
      searchPanel: {
        visible: true,
        searchVisibleColumnsOnly: true,
        width: 300
      },
      contactGrid: {},
      selection: {
        mode: "multiple",
        allowSelectAll: true,
        showCheckBoxesMode: "always"
      },
      openModal: "",
      modalProviderId: null,
      modalLocationId: null,
      pager: {
        allowedPageSizes: [10, 15, 20, 25, 50],
        showInfo: true,
        infoText: "Page {0} of {1} ({2} Contacts)",
        showPageSizeSelector: true,
        visible: true,
        displayMode: "full"
      },
      paging: {
        enabled: true
      },
      viewExpired: false
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      currentLab: state => state.currentLab,
      states: state => state.dropdowns.states
    }),
    ...mapGetters(["permissions"]),
    columns() {
      return [
        {
          sortIndex: 0,
          sortOrder: "asc",
          dataField: "providerFullName",
          caption: "Provider",
          cellTemplate: "providerName",
          dataType: "string"
        },
        {
          dataField: "locationName",
          caption: "Location Name",
          cellTemplate: "locationName",
          dataType: "string"
        },
        {
          dataField: "locationAddress1",
          caption: "Address",
          dataType: "string"
        },
        {
          dataField: "locationAddress2",
          caption: "Address 2",
          dataType: "string"
        },
        {
          dataField: "locationCity",
          caption: "City",
          dataType: "string"
        },
        {
          dataField: "state",
          lookup: {
            dataSource: this.states,
            valueExpr: "id",
            displayExpr: "displayName"
          }
        },
        { dataField: "zipCode" },
        {
          dataField: "primaryPhone",
          dataType: "string",
          caption: "Contact Phone Number",
          calculateCellValue({ primaryPhone }) {
            if (!primaryPhone) {
              return "";
            }
            return formatPhoneNumber(primaryPhone);
          },
          calculateSortValue: "primaryPhone",
          calculateFilterExpression(filterValue) {
            const cleaned = filterValue.replace(/\D/g, "");
            if (!cleaned) {
              return null;
            }
            return ["primaryPhone", "contains", cleaned];
          }
        },
        {
          dataField: "primaryFax",
          caption: "Contact Primary Fax",
          dataType: "string",
          calculateCellValue({ primaryFax }) {
            if (!primaryFax) {
              return "";
            }
            return formatPhoneNumber(primaryFax);
          },
          calculateSortValue: "primaryFax",
          calculateFilterExpression(filterValue) {
            const cleaned = filterValue.replace(/\D/g, "");
            if (!cleaned) {
              return null;
            }
            return ["primaryFax", "contains", filterValue.replace(/\D/g, "")];
          }
        },
        { dataField: "providerNPI", caption: "NPI", dataType: "string" },
        {
          dataField: "properties.expiryOn",
          caption: "ExpiryOn",
          dataType: "date",
          cellTemplate: "expirationCell",
          allowSearch: false
        },
        {
          dataField: "providerLegacyId"
        },
        {
          dataField: "locationLegacyId"
        },
        {
          type: "buttons",
          cellTemplate: "actions-cell",
          caption: "Actions",
          visibleIndex: 0,
          allowSearch: false
        }
      ];
    }
  },
  methods: {
    handleSubmit() {
      this.isEditing = false;
      this.contactGrid.clearSelection();
      this.contactGrid.refresh();
    },
    initGrid({ component }) {
      this.contactGrid = component;
    },
    initLocationGrid({ component }) {
      this.locationGrid = component;
    },
    setSelected({ selectedRowKeys }) {
      this.hasSelection = Boolean(selectedRowKeys.length);
      this.selectedRows = [];
    },
    editContact(key) {
      this.contactGrid.selectRows([key]);
      this.editSelected();
    },
    editSelected() {
      this.selectedRows = this.contactGrid.getSelectedRowsData();
      if (this.selectedRows?.length) {
        this.isEditing = true;
      } else {
        window.notify("Please select items to edit.", "warning");
      }
    },
    cancelEdit() {
      this.isEditing = false;
      this.isLoading = false;
      this.contactGrid.clearSelection();
    },
    dataService(options) {
      options.filterFields.forEach(e => {
        if (e.field === "ContactId") {
          e.field = "Id";
        }
      });
      return ProvidersApi.searchContacts(options);
    },
    calculateFax(data) {
      if (data?.properties?.faxMachines?.length) {
        const primary = data.properties.faxMachines.find(e => e.primary);
        return primary ? primary.phoneNumber : "N/A";
      }
      return "N/A";
    },
    calculatePhone(data) {
      return data?.provider?.phoneNumber || "N/A";
    },
    providerNameValue(data) {
      return data?.provider?.lastName + "," + data?.provider?.firstName;
    },
    locationNameValue(data) {
      return data?.location?.name;
    },
    providerName({ data }) {
      return data?.providerFullName;
    },
    locationName({ data }) {
      return data?.locationName;
    },
    directRouter(path, params = {}) {
      return this.$router.push({
        name: path,
        params: {
          shouldRedirect: true,
          ...params
        }
      });
    },
    editProvider(providerID) {
      this.openModal = "provider";
      this.modalProviderId = providerID;
    },
    editLocation(locationId) {
      this.openModal = "location";
      this.modalLocationId = locationId;
    },
    closeModal() {
      this.openModal = null;
      this.modalProviderId = null;
      this.modalLocationId = null;
      this.contactGrid.refresh();
    },
    exportToExcel() {
      this.contactGrid.exportToExcel();
    }
  },
  watch: {
    viewExpired: {
      handler(nv) {
        if (nv) {
          this.contactGrid.clearFilter("dataSource");
        } else {
          this.contactGrid.filter(dateRangeFilter("properties.effectiveOn", "properties.expiryOn"));
          this.contactGrid.refresh();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-btn {
  transition: all 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.nav-link {
  text-transform: capitalize;
  font-weight: 500;
}
.row {
  align-items: center;
}
#propertiesForm {
  width: 40%;
  & > .row:nth-child(even) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.contactLink {
  color: #337ab7;
  cursor: pointer;
}
::v-deep .dx-pager {
  justify-content: flex-start;
  color: $primary-dark;
  font-weight: 600;
  font-size: 1rem;
  .dx-pages {
    margin: auto;
  }
}
</style>
